import React from "react";
import { Navigate } from "react-router-dom";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "../redux/language/language.selectors";
import { checkForNonEnglishCharacters } from "../utils/helpers";

const RequiredUserDataGuard = ({ children, userAuthData }) => {
  if (
    !userAuthData?.contact_no ||
    userAuthData?.contact_no.length < 10 ||
    !userAuthData?.country_code ||
    !userAuthData?.email?.length ||
    userAuthData?.first_name?.length < 3 ||
    userAuthData?.last_name?.length < 3 ||
    !checkForNonEnglishCharacters(userAuthData.first_name) ||
    !checkForNonEnglishCharacters(userAuthData.last_name)
  ) {
    return <Navigate to="/confirm-user-data" state={userAuthData} replace />;
  }
  return children;
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
});

export default connect(mapStateToProps)(RequiredUserDataGuard);
